import { PATH_AUTH } from "./path";
import { Redirect, Route, Switch } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import { ApmRoute } from "@elastic/apm-rum-react";
import AuthProtect from "../components/AuthProtect";

export function renderRoutes() {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Switch>
				<ApmRoute
					exact
					path={PATH_AUTH.app.home}
					component={lazy(() => import("../views/Home"))}
				/>
				<ApmRoute
					exact
					path={PATH_AUTH.auth.login}
					component={lazy(() => import("../views/auth/LoginView"))}
				/>
				<ApmRoute
					exact
					path={PATH_AUTH.auth.otp}
					component={lazy(() => import("../views/auth/OtpView"))}
				/>
				<ApmRoute
					exact
					path={PATH_AUTH.auth.oauth}
					component={lazy(() => import("../views/auth/OauthView"))}
				/>
				<ApmRoute
					exact
					path={PATH_AUTH.auth.logout}
					component={lazy(() => import("../views/auth/LogoutView"))}
				/>
				<ApmRoute
					exact
					path={PATH_AUTH.auth.forgotPassword}
					component={lazy(() =>
						import("../views/auth/ResetPassView/ForgotPass")
					)}
				/>
				<ApmRoute
					exact
					path={PATH_AUTH.app.viewSharableLinkPage}
					component={lazy(() => import("../views/SharableLinkView"))}
				/>
				{/* <ApmRoute
          exact
          path={PATH_AUTH.auth.register}
          component={lazy(() => import("../views/auth/RegisterView"))}
        /> */}
				{protectedRoutes.map((route, i) => {
					return (
						<AuthProtect
							exact
							path={route.path}
							key={i}
							component={route.component}
						/>
					);
				})}
				<ApmRoute path="/404" component={lazy(() => import("../views/Home"))} />
				<Redirect to="/404" />
			</Switch>
		</Suspense>
	);
}

const protectedRoutes = [
	{
		exact: true,
		path: PATH_AUTH.app.rssCreator,
		component: lazy(() => import("../views/RSSCreator")),
	},
	{
		exact: true,
		path: PATH_AUTH.app.configModel,
		component: lazy(() => import("../views/ConfigModel")),
	},
	{
		exact: true,
		path: PATH_AUTH.app.projectList,
		component: lazy(() => import("../views/ProjectsList")),
	},
	{
		exact: true,
		path: PATH_AUTH.app.projectDetail,
		component: lazy(() => import("../views/ProjectDetail")),
	},
	{
		exact: true,
		path: PATH_AUTH.app.allSources,
		component: lazy(() => import("../views/AllSources")),
	},
	{
		exact: true,
		path: PATH_AUTH.app.allCompanies,
		component: lazy(() => import("../views/AllCompanies")),
	},
	{
		exact: true,
		path: PATH_AUTH.app.allKeywords,
		component: lazy(() => import("../views/AllKeywords")),
	},
	{
		exact: true,
		path: PATH_AUTH.app.viewNews,
		component: lazy(() => import("../views/ViewNews")),
	},
	{
		exact: true,
		path: PATH_AUTH.app.viewNotifications,
		component: lazy(() => import("../views/Notifications")),
	},
];
